<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-container">
      <div class="uk-align-left">
        <div class="uk-width-1-1 font-header">
          Import {{ headerTitle }}
        </div>
      </div>
    </div>
    <div class="uk-container">
      <upload-master-data />
    </div>
  </div>
</template>

<script>
import getHeaderTitle from '@/utils/header-title'
import UploadMasterData from './UploadMasterData'

export default {
  components: {
    UploadMasterData
  },
  computed: {
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  }
}
</script>
