<template>
  <div
    id="modal-date-confirm"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-edit"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <div
        class="uk-modal-title"
        style="color:#025231"
      >
        Konfirmasi Perubahan
      </div>
      <p style="font-size:15px">
        Apakah kamu ingin mengubah data ini? Data Kegiatan yang di ubah akan terhapus, jika mengubah tanggal panen/chickout.
      </p>
      <div style="padding-top:20px">
        <button
          class="uk-button uk-button-primary uk-margin-right uk-modal-close"
          type="button"
          :disabled="is_loading"
        >
          Batalkan
        </button>
        <button
          class="uk-button uk-button-default"
          type="button"
          :disabled="is_loading"
          @click="handleConfirm"
        >
          <span
            v-if="is_loading"
            uk-spinner
            class="button-spinner"
          />Lanjutkan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import {
  notificationDanger,
  notificationSuccess
} from '@/utils/notification'

export default {
  data() {
    return {
      is_loading: false,
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      modalEdit: 'activityCage/modalEdit'
    })
  },
  methods: {
    ...mapActions({
      updateActivityCage: 'activityCage/updateActivityCage'
    }),
    async handleConfirm () {
      try {
        this.is_loading = true
        await this.updateActivityCage(this.modalEdit)
        const res      = await this.$store.getters['activityCage/getUpdateActivityCageMsg']
        if (res.message === 'OK') {
          notificationSuccess(`Ubah Kegiatan Kandang "${this.modalEdit.name}" Berhasil`)
          setTimeout(function () {
            window.location.href = '/admin/kegiatan-kandang'
          }, 1000)
        } else {
          notificationDanger(`Something went wrong`)
        }
      } catch (error) {
        console.log(error.response)
        this.is_loading = false
      }
    }
  }
}
</script>
