<template>
  <div>
    <div class="uk-margin-top uk-card uk-card-default uk-padding simp-card">
      <form
        class="uk-form-stacked"
        @submit.prevent="handleSubmit"
      >
        <div class="uk-margin-bottom font-filter uk-text-bold">
          Import Data Akuisisi
        </div>
        <div class="uk-margin-small-bottom uk-text-bold uk-text-large">
          1. Pilih file yang ingin diupload:
        </div>
        <div>
          <p>Pilih Status: </p>
          <div class="uk-form-controls">
            <multiselect
              v-model="statusChoose"
              v-validate="'required'"
              track-by="value"
              label="value"
              name="partner-status"
              :show-labels="true"
              placeholder="Semua"
              open-direction="bottom"
              :options="statusMitraList.data"
              :searchable="true"
              :loading="false"
              :close-on-select="true"
              :class="{'uk-form-danger': errors.has('partner-status')}"
              :max-height="500"
              :show-no-results="true"
            >
              <span slot="noResult">Oops! Data tidak ditemukan.</span>
            </multiselect>
          </div>
          <span
            v-show="errors.has('partner-status')"
            class="uk-text-small uk-text-danger"
          >{{ errors.first('partner-status') }}</span>
        </div>
        <div class="uk-margin-top">
          <p>Pilih File: </p>
          <p v-if="file_import">
            {{ file_import.name }}
          </p>
        </div>
        <div class="uk-margin">
          <div uk-form-custom="target: true">
            <input 
              ref="file_import" 
              v-validate="'required'"
              type="file"
              name="file"
              accept="text/csv"
              :disabled="btn_upload"
              :class="{'uk-form-danger': errors.has('file')}"
              @change="(event) => handleUploadFile(event)"
            >
            <input
              class="uk-input uk-form-width-large"
              type="text"
              placeholder="Pilih .csv file"
              disabled
            >
            <button class="uk-button uk-button-upload">
              Browse
            </button>
          </div>
        </div>
        <span
          v-show="errors.has('file')"
          class="uk-text-small uk-text-danger"
        >{{ errors.first('file') }}</span>
        <div
          v-if="showLink"
          class="uk-text-small uk-text-secondary"
        >
          Berikut ini template file excel dan pastikan upload sesuai template.
          <a :href="showLink">Klik disini</a>
        </div>
        <div class="uk-margin-top uk-margin-small-bottom uk-text-bold uk-text-large">
          2. Upload Disini:
        </div>
        <div class="uk-margin-small-bottom uk-form-controls">
          <button
            class="uk-button uk-button-primary uk-width-xlarge uk-margin-small-button"
            type="submit"
          >
            <span
              uk-icon="icon: upload; ratio: 0.9"
              class="uk-margin-small-right"
            />Upload Disini
          </button> 
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  stringNotifDanger,
  notificationSuccess
} from '@/utils/notification'
import { PARTNER_STATUS, ACQUISITION_FILE_IMPORT_PATH } from '@/utils/constant'

export default {
  data() {
    return {
      statusChoose: '',
      showLink:'',
      file_import: null,
      btn_upload: false,
      statusMitraList:{
        data: [],
        isLoading: true
      },
      endPoint: '',
      formData: {}
    }
  },
  computed:{
    ...mapGetters({
      importResponse: 'acquisition/importResponse'
    })
  },
  watch:{
    importResponse(a,b){
      if(a != b){
        notificationSuccess('Berhasil Import Data!')
        setTimeout(function() { window.location = '/admin/akuisisi'}, 1000)
      }
    },
    statusChoose(){
      this.getCode(this.statusChoose.code)
    }
  },
  async mounted() {
    if(PARTNER_STATUS){
      this.statusMitraList = {
        data: PARTNER_STATUS.filter(item => item.code !== 10 && item.code !== 11),
        isLoading: false
      }
    }
  },
  methods: {
    getCode(code){
      this.linkList(code)
    },
    linkList(c){
      const getConstant = ACQUISITION_FILE_IMPORT_PATH.find(item => item.code === c)
      this.showLink = getConstant ? '/files/template-import/' + getConstant.value : ''
      this.endPoint = getConstant ? getConstant.endPoint : ''
    },
    ...mapActions({
      getListStatusAcquisition: "acquisition/getListStatusAcquisition",
      bulkImport: 'acquisition/bulkImport'
    }),
    async onUpload(scp) {
      this.btn_upload = true
      this.$validator.validateAll(scp).then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.uploadAction(scp)
        } else {
          this.btn_upload = false
        }
      })
    },
    handleUploadFile(data) {
      const file = data.target.files[0]
      const fileType = file.type
      if (fileType == 'text/csv'){
        this.errors.remove('formData.file')
        const formData = new FormData
        formData.append('file', file)
        formData.append('endPoint', this.endPoint)
        this.formData = formData
      } else {
        data.target.value = ""
        stringNotifDanger("Please select a .CSV file")
      }
    },
    handleSubmit(){
      this.$validator.validateAll().then(async (success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          await this.bulkImport(this.formData)
        }
      })
    }
  }
}
</script>

<style scoped>
.uk-button-upload{
  position: absolute;
  right: 0px;
  background: #F0F0F0;
  border: 1px solid #D4DFF5;
  border-radius: 0px 4px 4px 0px;
  box-shadow: none;
}
.uk-text-large{
  color: black;
}
p{
  color: #6D7278;
}
.uk-form-controls{
  width: 500px;
  color:#4DA0E7;
}
</style>
