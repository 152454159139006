<template>
  <div
    id="modal-show-alert-edit-activity"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-edit"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <div class="uk-modal-title uk-text-danger">
        <img
          v-lazy="`${images}/icon/warning.svg`"
          alt=""
          class="uk-margin-small-right"
        >
        Gagal Simpan
      </div>
      <span v-if="showAlert.type === 0">
        <p
          class="uk-margin-top"
          style="font-size:15px"
        >Data master tahapan <b>{{ showAlert.name }}</b> saat ini belum support, mohon hubungi admin</p>
      </span>
      <!-- <span v-if="showAlert.type === 1">
        <p class="uk-margin-top" style="font-size:15px">Data detail kegiatan <b>{{ showAlert.name }}</b> dari kandang yang anda pilih tidak memiliki master tahapan, mohon untuk periksa kembali data detail kegiatan</p>
      </span> -->
      <span v-if="showAlert.type === 2">
        <p
          class="uk-margin-top"
          style="font-size:15px"
        >Data master tahapan <b>{{ showAlert.name }}</b> dari kandang yang anda pilih tidak memiliki detail kegiatan, mohon untuk periksa kembali data master tahapan</p>
      </span>
      <div style="padding-top:20px">
        <button
          class="uk-button uk-button-primary uk-modal-close"
          type="button"
        >
          Tutup
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      images: PREFIX_IMAGE
    }
  },
  computed: {
    ...mapGetters({
      showAlert: 'activityCage/showAlert'
    })
  }
}
</script>
