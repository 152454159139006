<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-2-3@s font-header">
        Ubah Data
      </div>
      <div class="uk-width-1-3@s">
        <bread-crumb :bread-crumb="breadcrumb" />
      </div>
    </div>

    <container
      :data-activity="activityCageDetail"
      :data-delete-message="dataDeleteMsg"
      :contract-price="contractPrice"
    />
    <div id="container-modal-edit" />
    <modal-delete-confirm @dataResponse="onGetResponse" />
    <modal-out-confirm />
    <modal-save-confirm />
    <modal-date-confirm />
    <modal-contract-price />
    <modal-show-alert-edit-activity />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Container from './Container'
import ModalDeleteConfirm from './ModalDeleteConfirm'
import ModalOutConfirm from './ModalOutConfirm'
import ModalSaveConfirm from './ModalSaveConfirm'
import ModalDateConfirm from './ModalDateConfirm'
import ModalContractPrice from './ModalContractPrice'
import ModalShowAlertEditActivity from './ModalShowAlertEditActivity'
import BreadCrumb from '@/components/globals/breadcrumb'

export default {
  components: {
    Container,
    ModalDeleteConfirm,
    ModalOutConfirm,
    ModalSaveConfirm,
    ModalDateConfirm,
    ModalContractPrice,
    ModalShowAlertEditActivity,
    BreadCrumb
  },
  data() {
    return {
      dataDeleteMsg: {},
      breadcrumb: [
        {
          link: '/admin/kegiatan-kandang',
          title: 'Kegiatan Kandang'
        },
        {
          link: null,
          title: 'Ubah Data'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      activityCageDetail: 'activityCage/activityCageDetail',
      contractPrice: 'contractPrice/priceDetail'
    })
  },
  async mounted() {
    await this.getActivityCageDetail(this.$route.params.id)
    await this.getPriceDetail(this.activityCageDetail.data.contract_price_id)
  },
  methods: {
    ...mapActions({
      getActivityCageDetail: 'activityCage/getActivityCageDetail',
      getPriceDetail: 'contractPrice/getPriceDetail'
    }),
    onGetResponse (data) {
      this.dataDeleteMsg = data
    }
  }
}
</script>
